/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-18 20:20:28
 * @Module: 专栏Item
 */
 <template>
  <div class="columItem hover"
       @click="toDetail">
    <div class="columItem-image"
         :style="'background-image:url('+data.cover+')'">
      <div class="columItem-image-content">
        <div>17</div>
        <div class="iconfont iconwenzhang"
             v-if="data.type===1"></div>
        <div class="iconfont iconshipin"
             v-if="data.type===0"></div>
      </div>
    </div>
    <div class="ellipsis2 columItem-text ellipsis2">{{data.title}}</div>
    <div class="columItem-time">更新时间 {{data.last_update_time}}</div>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {
    toDetail () {
      if (this.data.moment_content) {
        const { type, uuid } = this.data.moment_content
        this.$store.dispatch('homepage/toDetail', { type, uuid })
      } else {
        this.$message.warning("该专栏暂无内容")
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.columItem {
  width: 191px;
  &-image {
    width: 191px;
    height: 107px;
    border-radius: 2px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: center;
    &-content {
      width: 70px;
      height: 107px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 2px;
      color: #fff;
      float: right;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      div {
        margin: 7px;
      }
    }
  }
  &-text {
    margin-top: 8px;
    font-size: 14px;
    color: #333;
    line-height: 20px;
    // background: #66f;
    height: 40px;
  }
  &-time {
    font-size: 14px;
    color: #999;
    margin-top: 8px;
  }
}
</style>