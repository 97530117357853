/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-13 15:28:36
 * @Module:个人中心 
 */
 <template>
  <div class="personal-box"
       v-loading="loading">
    <div class="personal-nav">
      <div v-for="item in navList"
           :key="item.key"
           class="hover"
           @click="()=>{loading=true;active=item.key;}">
        {{item.title}}
        <div class="active"
             v-show="active==item.key"></div>
      </div>
    </div>
    <div v-show="active=='column'"
         class="column-box">
      <div class="personal-columnTitle">{{is_myself?'我的':'他的'}}专栏（{{pagination.total}}）</div>
      <div class="column-box-box">
        <colum-item class="personal-columnItem"
                    v-for="(item,index) in list"
                    :data="item"
                    :key="index" />
      </div>
    </div>
    <div v-show="active !=='column'">
      <item v-for="(item,index) in list"
            :key="item.uuid"
            :data="item"
            :itemindex="index"
            :showCommentId="showCommentId"
            personal
            @footer-click="onclick"
            @header-click="header_click" />
    </div>
    <div class="homepage-pagination"
         v-show="pagination.total">
      <el-pagination background
                     hide-on-single-page
                     layout="prev, pager, next,jumper"
                     :current-page="pagination.page"
                     @current-change="currentChange"
                     :page-size="pagination.pageSize"
                     :total="pagination.total">
      </el-pagination>
    </div>
    <no-data v-show="!pagination.total" />
    <transpond-dialog :visible="transpondDialogVisible"
                      :data="selectData"
                      @close="transpond_dialog_change" />
  </div>
</template>
 <script>
import momentListMixins from "@/views/homepage/momentListMixins"
import noData from "@/components/noData"
import userInfo from "@/mixins/userinfo"
import columItem from "@/views/homepage/user/module/columItem"
export default {
  components: {
    noData,
    columItem
  },
  mixins: [momentListMixins, userInfo],
  data () {
    return {
      loading: true,
      navList: [
        {
          title: '全部',
          key: 0
        },
        {
          title: '文章',
          key: 1
        },
        {
          title: '视频',
          key: 2
        },
        {
          title: '专栏',
          key: "column"
        },
        {
          title: '精选',
          key: 3
        },
        {
          title: '收藏',
          key: 4
        }
      ],
      active: 0,
      pagination: {
        total: 0,
        pageSize: 20,
        page: null
      },
    };
  },
  mounted () { },
  created () {
  },
  methods: {
    async _get_list (page = 1, profile_tab = this.active) {
      this.loading = true
      const { status, data, meta } = await (profile_tab == "column" ? this.$api.columnProfileListApi({ page, user: this.$route.query.uuid }) : this.$api.momentListApi({ page, user: this.$route.query.uuid, profile_tab }))
      this.loading = false
      if (status == 200) {
        this.list = data
        this.pagination.total = meta.pagination.total
        this.pagination.pageSize = meta.pagination.per_page
        // sessionStorage.setItem("page", meta.pagination.current_page);
      } else {
        console.log(status)
      }
    },
    // 底部点击事件
    onclick ({ type, data, index }) {
      //  收藏：collect；转发：transpond；评论：comment；点赞：like；
      if (type == "comment") {
        // this.commentClick(data)
        this.commentClick(data)

      } else if (type == "like") {
        this.likeClick(data, index)
      } else if (type == "collect") {
        this.collectClick(data, index)
      } else if (type == "transpond") {
        this.transpondClick(data)
      }
    },
  },
  watch: {
    active: {
      handler: function (val) {
        this._get_list(1, val)
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
};
 </script>
 <style lang='scss' scoped>
.personal-nav {
  display: flex;
  line-height: 50px;
  background: #fff;
  padding-left: 30px;
  border-bottom: solid 1px #eee;
  & > div {
    margin-right: 40px;
  }
  .active {
    width: 30px;
    height: 3px;
    background: #df2a29;
    border-radius: 2px;
  }
}
.homepage-pagination {
  display: flex;
  justify-content: center;
  background: #fff;
  margin-top: -10px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.personal-columnTitle {
  height: 58px;
  background: #fff;
  padding-top: 20px;
  font-size: 20px;
}
.personal-columnItem {
  margin-right: 20px;
  margin-bottom: 26px;
  &:nth-child(4n) {
    margin-right: 0px;
  }
}
.column-box {
  background: #fff;
  padding: 0 30px;
}
.column-box-box {
  display: flex;
  flex-wrap: wrap;
}
</style>